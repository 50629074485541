@charset "UTF-8";
/* CSS Document */


/* ---------------------------- MOET ER ALTIJD IN ! ---------------------------- */
@-ms-viewport {
  zoom:1;
  width:extend-to-zoom;
}
@viewport {
  zoom:1;
  width:extend-to-zoom;
}
/* ------------------ normalize tags ------------------ */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #111;
}
* {
  box-sizing: border-box;
}
p {
  margin-top: 0;
  margin-bottom: 0.5em;
}
p strong {
}
h1{
  line-height: 1.1em;
  color: #fff;
  margin-top: 1em;
  margin-bottom: 1em;
}
table p {
  margin-bottom: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 0;
  width: 100%
}
td {
  border: 1px solid #ccc;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align:top;
}
ul {
  margin-bottom:0;
}
ul ul {
  margin-bottom:0;
}
ul li {
  margin-left:0;
}
hr {
  float: left;
  clear: both;
  width:100%;
  margin-top: 1em;
  margin-bottom:1em;
  border: 0;
}
img {
  display:block;
  max-width:100%;
  height:auto;
}
p img {
  display:inline-block;
}
button {
  font-size: 1em;
  border: 0;
  width: auto;
  margin-bottom: 1em;
  border-radius: 5px;
  padding-top: 0.4em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.5em;
  background-color: #aaa;
  color: #000;
}
input {
  height: 2em;
  padding-left: 10px;
  background-color: #ccc;
  margin-bottom: 0.5em;
  font-size: 1em;
  border: 0;
  border-bottom: solid 1px #eee;
}
body {
  font-weight: normal;
  font-family: sans-serif;
  font-size: 15px;
  line-height: 1.3em;
  color: #fff;
}
a:link, a:active, a:hover, a:visited {
  color: #960;
  text-decoration: underline;
}
button a:link, button a:active, button a:hover, button a:visited {
  color: #960;
  text-decoration: none;
}