@charset "UTF-8";
/* CSS Document */


/* ---------------------------- MOET ER ALTIJD IN ! ---------------------------- */
@-ms-viewport {
    zoom:1;
    width:extend-to-zoom;
}
@viewport {
    zoom:1;
    width:extend-to-zoom;
}
/* ------------------ normalize tags ------------------ */
html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: #000;
}
* {
    box-sizing: border-box;
}
p {
    margin-top: 0;
    margin-bottom: 0.5em;
}
p strong {
}
h1{
    line-height: 1.1em;
    color: #fff;
    margin-top: 1em;
    margin-bottom: 1em;
}
table p {
    margin-bottom: 0;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 0;
    width: 100%
}
td {
    border: 1px solid #ccc;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align:top;
}
ul {
    margin-bottom:0;
}
ul ul {
    margin-bottom:0;
}
ul li {
    margin-left:0;
}
hr {
    float: left;
    clear: both;
    width:100%;
    margin-top: 1em;
    margin-bottom:1em;
    border: 0;
}
img {
    display:block;
    max-width:100%;
    height:auto;
}
p img {
    display:inline-block;
}
button {
    font-size: 1em;
    border: 0;
    width: auto;
    margin-bottom: 1em;
    border-radius: 5px;
    padding-top: 0.4em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0.5em;
    background-color: #aaa;
    color: #000;
}
input {
    height: 2em;
    padding-left: 10px;
    background-color: #ccc;
    margin-bottom: 0.5em;
    font-size: 1em;
    border: 0;
    border-bottom: solid 1px #eee;
}
body {
    font-weight: normal;
    font-family: sans-serif;
    font-size: 15px;
    line-height: 1.3em;
    color: #fff;
    margin:0;
}
a:link, a:active, a:hover, a:visited {
    color: #960;
    text-decoration: underline;
}
button a:link, button a:active, button a:hover, button a:visited {
    color: #960;
    text-decoration: none;
}
/* ------------------ much used ------------------ */
.Centered {
    text-align: center;
    float: left;
    width: 100%;
}
.Centered .Vraag {
    padding-left: 10%;
    padding-right: 10%;
}
.Smal {
    padding-left: 15%;
    padding-right: 15%;
}
.ButtonGeneral {
    padding-left: 1.25em;
    padding-right: 1.25em;
    font-size: 0.95em;
    margin-bottom: 1em;
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #999;
}
.ButtonGeneral:disabled {
    color: #555;
    background-color: #222;
    border-top: solid 1px #333;
    border-bottom: solid 1px #111;
}
.ButtonGeneral.Rood {
    background-color: #800;
    color: #fff;
    border-top: solid 1px #b00;
    border-bottom: solid 1px #500;
    padding-top: 0.75em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-bottom: 1em;
}
button.NaamWeergave {
    background-color: RGBA(0,0,0,0);
    border: solid 1px #444;
    border-radius: 2px;
    color: #bbb;
    margin-top: 0;
    margin-right: 10px;
    margin-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
}
button.NaamWeergave.Klein {
    margin-right: 0;
    margin-bottom: 0;
    padding-left: 0.5em;
    padding-right: 0.5em;
}
.Dag button.NaamWeergave, .Textballon button.NaamWeergave {
    border: solid 1px #ddd;
    color: #fff;
}
.Dag button.NaamWeergave.Klein {
    border: solid 1px #909090;
    color: #aaa;
}
button.JaNee {
    width: 5em;
    margin-top: 0.75em;
    margin-right: 10px;
    background-color: #333;
    color: #ccc;
}
button.JaNee.Selected {
    background-color: #005080;
    color: #fff;
    font-weight: bold;
}
button.JaNee:disabled {
    background-color: #000;
    border: solid 1px #333;
    color: #555;
}
.Bigtxt {
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.5em;
}
.App {
    background-color: #000;
    text-align: left;
    max-width: 400px;
    margin: auto;
}
.Breadcrumb, .VersionNumber {
    color: #666;
    font-size: 0.9em;
    margin-bottom: 2em;
    float: left;
    width: 100%;
}
.Ochtendgloren .Breadcrumb {
    color: #999;
}
.Dag .Breadcrumb {
    color: #bbb;
}
.Schemering .Breadcrumb {
    color: #888;
}
.Vraag {
    font-weight: bold;
    color: #fff;
    float: left;
    width: 100%;
    margin-top: 0.5em;
}
.Uitleg {
    font-size: 0.95em;
    float: left;
    width: 100%;
    color: #909090;
}
.Spacer {
    float: left;
    width: 100%;
    font-size: 0px;
    height: 1px;
}
.Spacer.Een {
    margin-bottom: 10px;
}
.Spacer.Twee {
    margin-bottom: 20px;
}
.Spacer.Drie {
    margin-bottom: 30px;
}
.Spacer.Vier {
    margin-bottom: 30px;
}
.Icon img {
    height: 1em;
    width: auto;
    display: inline-block;
}


/* ---------------------------- APP HEADER ---------------------------- */
.AppHeader {
    float: left;
    width: 100%;
    margin-bottom: 1em;
    border-bottom: solid 1px #333;
    color: #960;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #000;
}
.HeaderLogo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    width: auto;
}
.HeaderLogo img {
    height: 30px;
    width: auto;
}
.Menu button {
    font-size: 0.9em;
    border: 0;
    height: 1em;
    width: auto;
    margin-left: 1em;
    margin-bottom: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: #000;
    color: #960;
}

/* ---------------------------- HEADER MENU tijdens voorbereiding ---------------------------- */
.Menu.Prep {
    float: right;
    margin-top: 10px;
}


/* ---------------------------- HEADER MENU tijdens spel ---------------------------- */
.Menu.Spel {
    float: right;
    margin-top: 10px;
}

/* ---------------------------- APP BODY ---------------------------- */
.AppBody {
    background-color: #000;
    padding-left: 15px;
    padding-right: 15px;
}
.AppBody.Nacht {
    background-color: #000;
}
.AppBody.Ochtendgloren {
    background: #222;
    background: -webkit-linear-gradient(#402b00 0%, #000 100%);
    background: -o-linear-gradient(#402b00 0%, #000 100%);
    background: linear-gradient(#402b00 0%, #000 100%);
}
.AppBody.Dag {
    background-color: #132026;
    background: -webkit-linear-gradient(#058 0%, #000 100%);
    background: -o-linear-gradient(#058 0%, #000 100%);
    background: linear-gradient(#058 0%, #000 100%);
}
.AppBody.Schemering {
    background: #222;
    background: -webkit-linear-gradient(#500 0%, #000 100%);
    background: -o-linear-gradient(#500 0%, #000 100%);
    background: linear-gradient(#500 0%, #000 100%);
}

/* ---------------------------- startscherm ---------------------------- */
.NieuwSpel {
    padding-top: 1.25em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1.25em;
    border-radius: 6px;
    background-color: #960;
    color: #fff;
    font-weight: bold;
    font-size: 1.1em;
    line-height: 1em;
    margin-top: 1em;
    margin-right: 0;
    margin-bottom: 2em;
}
.ButtonLinkLike {
    color: #960;
    font-weight: bold;
    background-color: RGBA(0, 0, 0, 0);
}


/* ---------------------------- Add Player ---------------------------- */
.AddPlayerName {
    float: left;
    width: 100%;
}
.AddPlayerName p {
    margin-bottom: 0.5em;
}
.AddPlayerName input {
    float: left;
    width: 100%;
}
.AddPlayerName .ButtonGeneral {
    float: right;
    margin-right: 5%;
}

/* ---------------------------- namen selecteren ---------------------------- */
.NamenSelecteren {
    float: left;
    width: 100%;
    margin-top: 0.25em;
    margin-bottom: 1em;
}
button.SpelerNaam {
    float: left;
    padding-left: 0.8em;
    padding-right: 0.8em;
    padding-top: 0.55em;
    padding-bottom: 0.7em;
    margin-right: 1em;
    margin-bottom: 1em;
    background-color: #333;
    color: #ccc;
    font-size: 1em;
}
button.SpelerNaam.Selected {
    background-color: #069;
    font-weight: bold;
    color: #fff;
}
.Dag button.SpelerNaam {
    background-color: RGBA(0,0,0,0.5)
}
.Dag button.SpelerNaam.Selected {
    background-color: #069;
}
.Verwijderen button.SpelerNaam.Selected {
    background-color: #900;
}

/* ---------------------------- rollen selecteren ---------------------------- */
.WBwrapper {
    float: left;
    width: 100%;
    margin-top: 0.5em;
    padding-top: 0.5em;
}
.WBwrapper hr {
    height: 1px;
    background-color: #666;
    color: #666;
    width: 100%;
    margin: 0;
    margin-top: 2em;
    border: 0;
}
.WBwrapper button {
    color: #666;
    background-color: #000;
    border: solid 1px #666;
    border-radius: 2px;
    border-bottom: solid 1px #666;
    margin-top: -2.3em;
    margin-bottom: 0;
    padding-left: 1em;
    padding-right: 1em;
}
.W {
    float: left;
    margin-left: 5%;
}
.W:disabled {
    color: #fff;
    border-bottom: 0;
}
.B {
    float: right;
    margin-right: 5%;
}
.B:disabled {
    color: #fff;
    border-bottom: 0;
}
.RollenKiezen button {
    border: 0;
    background-color: #000;
    color: #666;
    font-size: 0.9em;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: 5px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5px;
    height:auto;
    width: 108px;
    vertical-align: top;
}
.RollenKiezen .Rand {
    display: block;
    border-radius: 4px;
    border: solid 3px #000;
}
.RollenKiezen button.Selected .Rand {
    border: solid 3px #fff;
}
.RollenKiezen button.Selected {
    color: #fff;
}
.RollenKiezen img {
    width: 100px;
    height: auto;
    opacity: 0.7;
}
.RollenKiezen button.Selected img {
    opacity: 1;
}
.TellerWrapper {
    float: left;
    width: 100%;
}
.MetTeller {
    float: left;
    margin-left:20%;
}
.RolBijTeller {
    float: left;
}
.Teller {
    float: left;
    margin-top: 30px;
    font-weight: bold;
}
button.MinPlus {
    background-color: #111;
    padding-top: 0;
    padding-bottom: 0.1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 1.5em;
    border: solid 1px #555;
    margin-left: 5px;
    margin-right: 5px;
    width: auto;
    vertical-align:middle;
}
.Teller .Cijfer {
    font-size: 1.25em;
    color: #ccc;
}


/* ---------------------------- gekozen rollen ---------------------------- */
.GekozenRollen {
    margin-bottom: 1em;
}
.GekozenRollen img {
    display: inline-block;
    width: 75px;
    height: 75px;
    margin: 5px;
    border: solid 2px #222;
}
.GekozenRollen button {
    margin: 0;
    padding: 0;
    background: #000;
    font-size: 0px;
}
.GekozenRollen button img {
    border: 0;
    margin: 0;
    padding: 0;
    opacity: 0.7;
    width: 71px;
    height: 71px;;
}
.GekozenRollen button .Rand {
    border: solid 2px #222;
    display: inline-block;
    margin: 4px;
}
.GekozenRollen button.Selected .Rand {
    border: solid 2px #fff;
}
.GekozenRollen button.Selected .Rand img {
    opacity: 1;
}

.StartSpel {
    padding-top: 1.5em;
    padding-left: 2.2em;
    padding-right: 2.2em;
    padding-bottom: 1.5em;
    border-radius: 6px;
    background-color: #960;
    color: #fff;
    font-weight: bold;
    font-size: 1.1em;
    line-height: 1em;
    margin-top: 1em;
    margin-right: 0;
    margin-bottom: 1em;
}
.StartSpel::after {
    content: " →";
}

/* ---------------------------- tekst ballon ---------------------------- */
.BallonWrapper {
    float: left;
    width: 100%;
    margin-top: 1.25em;
    margin-bottom: 1em;
}
.Textballon {
    background-color: #080;
    border-radius: 10px;
    padding-top: 1em;
    padding-left: 20px;
    padding-right: 15px;
    padding-bottom: 1.1em;
    color: #fff;
    font-size: 1em;
    line-height: 1.35em;
    font-weight: bold;
}
.BallonDing {
    color: #080;
    margin-top: 0em;
    margin-left: 85%;
    font-size: 2em;
}


/* ---------------------------- actie tekstblok ---------------------------- */
.Actie {
    float: left;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
}
.Actie hr {
    height: 2px;
    background-color: #069;
    color: #069;
    width: 100%;
    margin: 0;
    border: 0;
    float: left;
}
.Actie .first hr {
    margin-bottom: 1.5em;
}
.Actie .second hr {
    margin-top: 0.9em;
    margin-bottom: 0.5em;
}
.ActieDing {
    color: #069;
    margin-top: -1.8em;
    margin-left: 15px;
    float: left;
}
.Actie p {
    padding-left: 15px;
    padding-right: 15px;
}

/* ---------------------------- Klok ---------------------------- */
.KlokWrapper {
    float: left;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 2em;
    margin-bottom: 3em;
    padding-top: 1.75em;
    padding-bottom: 1.5em;
    border: solid 2px #fff;
}
.KlokWrapper.Groen {
    border: solid 2px #080;
}
.KlokWrapper.Geel {
    border: solid 3px #fc0;
}
.KlokWrapper.Bruin {
    border: solid 4px #960;
}
.KlokWrapper.Rood {
    border: solid 5px #900;
}
.KlokWrapper.Felrood {
    border: solid 6px #f30;
}
.Klok {
    text-align: center;
    float: left;
    width: 100%;
    font-size: 2.5em;
}


/* ---------------------------- Terug en Verder knoppen ---------------------------- */
.TerugVerder {
    float: left;
    width: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
}
.ButtonTerug {
    float: left;
    padding-left: 0;
    padding-right: 0;
    color: #960;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
}
.ButtonTerug::before {
    content: "← ";
}
.ButtonVerder {
    float: right;
    width: auto;
    color: #fff;
    background-color: #960;
}
.ButtonVerder::after {
    content: " →";
}
.ButtonTerug:disabled {
    opacity: 0.3;
}
.ButtonVerder:disabled {
    opacity: 0.3;
}


/* ---------------------------- APP FOOTER ---------------------------- */
.AppFooter {
    float: left;
    width: 100%;
    border-top: solid 1px #333;
    border-bottom: solid 1px #333;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 0.95em;
    color: #333;
    background-color: #000;
    padding-left: 15px;
    padding-right: 15px;
}
.end {
    color: RGBA(0,0,0,0);
}
.AppFooter a:link, .AppFooter a:active, .AppFooter a:hover, .AppFooter a:visited {
    color: #644200;
    text-decoration: none;
    margin-left: 5px;
    margin-right: 5px;
}
